<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
  <Navbar />
  <!-- <Header msg="Rockstar Nailbomb" /> -->
  <MediaBox />
</template>

<script>
import Navbar from "./components/Navbar.vue";
// import Header from "./components/Header.vue";
import MediaBox from "./components/MediaBox.vue";

export default {
  name: "App",
  components: {
    Navbar,
    // Header,
    MediaBox,
  },
};
</script>

<style lang="scss">
body {
  background-color: #121212;
  margin: 0px;
}
</style>
