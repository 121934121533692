<template>
  <div class="media-box-container">
    <div class="media-box-item">
      <Card>
        <template #header>
          <img
            alt="user header"
            width="560"
            height="315"
            src="../assets/media.jpg"
          />

          <!-- Photo by <a href="https://unsplash.com/@williamk?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">William Krause</a> on <a href="https://unsplash.com/s/photos/music-rock?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a> -->

          <!-- <div style="display: flex; justify-content: center">
            <iframe
              width="560"
              height="315"
              src=""
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div> -->
        </template>
        <template #title> Welcome </template>
        <template #content>
          <div class="mb-post">
            <p>Just playing around with Vue.js</p>
          </div>
        </template>
        <template #footer>
          <!-- <Button icon="pi pi-plus" label="Add" /> -->
          <!-- <Button
            icon="pi pi-times"
            label="Cancel"
            class="p-button-secondary"
            style="margin-left: 0.5em"
          /> -->
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: "PrimeVue",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.media-box-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.mb-post {
  max-width: 560px;
}
</style>
