<template>
  <Menubar :model="items">
    <template #start>
      <i class="pi pi-fw pi-spinner" style="fontsize: 1.5rem"></i>
    </template>
  </Menubar>
</template>
<script>
</script>
<style scoped>
#logo {
  height: 75px;
}
.p-menubar {
  border-radius: 0px;
  border-top: none;
  border-left: none;
  border-right: none;
}
</style>