import { createApp } from "vue";
import App from "./App.vue";

import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Card from 'primevue/card';
import Menubar from 'primevue/menubar';


import 'primevue/resources/themes/arya-orange/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                           //icons

const app = createApp(App);
app.use(PrimeVue);

app.component('InputText', InputText);
app.component('Button', Button);
app.component('Card', Card);
app.component('Menubar', Menubar);

app.mount("#app");